<template>
  <div>
    <basic-container>
      <avue-crud :option="tableOption"
                 :data="tableData"
                 :defaults.sync="defaults"
                 :table-loading="tableLoading"
                 v-model="form"
                 ref="crud"
                 :before-open="beforeOpen"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">


        <template slot="workshopId" slot-scope="scope">
          {{ screenWorkshopId(scope) }}
        </template>

        <template slot="shiftId" slot-scope="scope">
          {{ screenShiftId(scope) }}
        </template>

        <template slot-scope="{type,disabled}" slot="workshopIdForm">
          <el-select v-model="form.workshopId" @change="getProcessList" placeholder="请选择车间">
            <el-option
                v-for="item in workShopList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </template>

        <template slot-scope="{type,disabled}" slot="processIdListForm">
          <el-select v-model="form.processIdList" placeholder="请选择工序" multiple>
            <el-option
                v-for="item in processList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </template>

        <template slot-scope="{type,disabled}" slot="shiftIdForm">
          <el-select v-model="form.shiftId" placeholder="请选择班组类型">
            <el-option
                v-for="item in shiftSelectTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </template>


        <template slot="status" slot-scope="scope">
          <!--          <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"-->
          <!--                     @change="change(scope.row)"></el-switch>-->
        </template>
        <!--        <template slot="menuLeft">-->
        <!--          <importBtn-->
        <!--              :outApi="this.proApis.DEPARTMENTEXPORTGROUP"-->
        <!--              :innerAPI="this.proApis.DEPARTMENTIMPORT"-->
        <!--              :API="this.proApis.DEPARTMENTIMPORTTEMPLATE"-->
        <!--              @refresh="refreshData"-->
        <!--          ></importBtn>-->
        <!--        </template>-->
        <template slot="menuRight">
          <el-select v-model="form.workshopId" placeholder="请选择车间" size="small"
                     style="width:200px !important;marginRight: 5px;">
            <el-option
                v-for="item in workShopList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="form.shiftId" placeholder="请选择班组类型" size="small"
                     style="width:200px !important;marginRight: 5px;">
            <el-option
                v-for="item in shiftSelectTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-input v-model="nameOrCode" placeholder="nameOrCode" style="width:200px;marginRight: 5px;"
                    size="small"></el-input>
        </template>
        <template slot="menu" slot-scope={row}>
          <!--          <el-button icon="el-icon-data-analysis" :size="scope.size" :type="scope.type" @click="goReport(scope.row)">-->
          <!--            查看报表-->
          <!--          </el-button>-->
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list, add, edit, remove} from "@/api/scheduleProcess"
import {getProcess, getShiftSelectType, getWorkShop} from "@/api/changeShiftManagement";
import importBtn from "@/components/importExport/importBtn";

export default {
  components: {
    importBtn
  },
  data() {
    return {
      form: {},
      defaults: {},
      //searchParams
      nameOrCode: '',
      workshopId: '',
      shiftId: '',
      //车间
      workShopList: [],
      //工序
      processList: [],
      //班组类型
      shiftSelectTypeList: [],
      tableLoading: false,
      nameOrIdLike: "",
      page: {
        pageSize: 20,
        pagerCount: 5,
        total: 0,
        pageCurrent: 1
      },
      disabled: true,
      tableData: [],
      tableOption: {
        editBtn: true,
        delBtn: true,
        menuWidth: 200,
        selection: true,
        tip: false,
        columnBtn: false,
        dialogFullscreen: true,
        addBtnText: this.$t('ADD'),
        delBtnText: this.$t('DELETE'),
        editBtnText: this.$t('Edit'),
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [
          {
            label: '名称',
            prop: "name",
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER A NAME'),
              trigger: "change"
            }],
          },
          {
            label: '编码',
            prop: "code",
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER A NAME'),
              trigger: "change"
            }],
          },
          {
            label: '车间',
            formslot: true,
            //hide: true,
            slot: true,
            display: true,
            //dicUrl: this.businessUrl + "department/selectWorkShopName?type=1",
            prop: "workshopId",
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER A NAME'),
              trigger: "change"
            }],
            control: (val, form) => {
              //console.log(val, form)
            },
          },
          {
            label: '班制',
            type: "select",
            //hide: true,
            slot: true,
            formslot: true,
            prop: "shiftId",
            props: {
              label: "name",
              value: "id"
            },
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER A NAME'),
              trigger: "change"
            }],
          },
          {
            label: '工序',
            formslot: true,
            //dicUrl: this.businessUrl + "department/selectWorkShopName?type=1",
            prop: "processIdList",
            formatter(row) {
              let temp_arr = row.processIdList.map((item => {
                return item.name
              }))
              return temp_arr.join(',')
            },
            rules: [{
              required: true,
              message: this.$t('PLEASE ENTER A NAME'),
              trigger: "change"
            }],
          },

        ]
      }
    }
  },
  created() {
    this.list();
    this.getWorkShopList()
    this.getShiftSelectTypeList()
  },
  methods: {
    screenWorkshopId({row}) {
      let w_name = this.workShopList.find((item) => {
        return item.id === row.workshopId
      })
      return w_name['name']
    },
    screenShiftId({row}) {
      let s_name = this.shiftSelectTypeList.find((item) => {
        return item.id === row.shiftId
      })
      return s_name['name']
    },
    rowEdit(row) {
      console.log(row)
      this.$refs.crud.rowEdit(row)
    },
    async getWorkShopList() {
      let res = await getWorkShop();
      this.workShopList = res.data.data;
    },
    getProcessList(v) {
      this.form.processIdList = [];
      getProcess(v).then(res => {
        this.processList = res.data.data;
      })
    },
    getShiftSelectTypeList() {
      getShiftSelectType().then(res => {
        this.shiftSelectTypeList = res.data.data;
        this.shiftSelectTypeList.forEach((item) => {
          item.id = item.id + ""
        })
      })
    },
    goReport(item) {
      console.log(item)
      this.$router.push({
        path: '/searchDashboard/reportStyle',
        query: {type: 'timp', id: item.id, parentId: item.parentId}
      })
    },
    currentChange(pageCurrent) {
      this.page.pageCurrent = pageCurrent;
      this.list();
    },
    sizeChange(pageSize) {
      this.page.pageCurrent = 1;
      this.page.pageSize = pageSize;
      this.list();
    },
    refreshData() {
      this.list();
    },
    // getPermission(key, row, index) {
    //   if (key==='delBtn' && row.status === 0 ) {
    //     return false;
    //   } else if (key==='editBtn' && row.status === 0 ){
    //     return false;
    //   }
    //   return true;
    // },
    beforeOpen(done, type) {
      console.log(this.form)
      if (['view', 'edit'].includes(type)) {
        // 查看和编辑逻辑
        getProcess(this.form.workshopId).then(res => {
          this.processList = res.data.data;
        })
        console.log(this.defaults)
        this.defaults.name.display = false
      } else {
        //新增逻辑
        this.defaults.name.display = true
      }
      done()
    },
    handleDel(row) {
      this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        remove(row.id).then(res => {
          if (res.data.code == "BUSINESS.0005") {
            this.$message({
              type: "error",
              message: this.$t('FAILED TO DELETE. PLEASE ENSURE THAT THE SUBORDINATE ORGANIZATION HAS BEEN DELETED FIRST')
            });
          } else {
            if (res.data.code == "0000") {
              this.$message({
                message: this.$t('DELETE SUCCEEDED, REFRESH DATA'),
                type: "success"
              });
            }
          }
          this.list();
        })
      })
    },
    handleUpdate(row, index, done) {
      edit(row).then((res) => {
        if (res.data.code == "0000") {
          this.$message({
            message: "修改成功",
            type: "success"
          });
        }
        this.list();
        done();
      })
    },
    handleSave(row, done) {
      add(row).then((res) => {
        if (res.data.code == "0000") {
          this.$message({
            message: "新增成功",
            type: "success"
          });
        }
        this.list();
        done();
      })
    },
    selectionChange(list) {
      console.log(list);
      list != "" ? this.disabled = false : this.disabled = true;
    },
    refreshChange() {
      this.list();
    },
    list() {
      list(this.shiftId, this.nameOrCode, this.workshopId).then(res => {
        console.log('res', res);
        if (res.data.data == null) {
          this.tableData = [];
          this.page.total = 0;
        } else {
          this.page.total = res.data.data.length;
          this.tableData = res.data.data;
          console.log('this.tableData', this.tableData);
          // this.tableData.forEach(v => {
          //     this.status = v.status;
          // })
        }
      })
    },
    envText: function () {
      return this.env
    }
  }
}
</script>
<style scoped>
</style>
