import request from '@/router/axios';
import {businessUrl} from "@/config/env"

export const list = (shiftId, nameOrCode, workshopId) => {
    return request({
        url: businessUrl + "scheduleProcess/select",
        method: "post",
        data: {
            shiftId,
            nameOrCode,
            workshopId
        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url: businessUrl + "scheduleProcess/update",
        method: "post",
        data: {
            ...row
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url: businessUrl + "scheduleProcess/delete",
        method: "delete",
        params: {
            id
        }
    })
}

export const add = (row) => {
    return request({
        url: businessUrl + "scheduleProcess/insert",
        method: "post",
        data: {
            ...row
        }
    })
}