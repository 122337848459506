var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                defaults: _vm.defaults,
                "table-loading": _vm.tableLoading,
                "before-open": _vm.beforeOpen
              },
              on: {
                "update:defaults": function($event) {
                  _vm.defaults = $event
                },
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "workshopId",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.screenWorkshopId(scope)) +
                          "\n      "
                      )
                    ]
                  }
                },
                {
                  key: "shiftId",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.screenShiftId(scope)) +
                          "\n      "
                      )
                    ]
                  }
                },
                {
                  key: "workshopIdForm",
                  fn: function(ref) {
                    var type = ref.type
                    var disabled = ref.disabled
                    return [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择车间" },
                          on: { change: _vm.getProcessList },
                          model: {
                            value: _vm.form.workshopId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "workshopId", $$v)
                            },
                            expression: "form.workshopId"
                          }
                        },
                        _vm._l(_vm.workShopList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ]
                  }
                },
                {
                  key: "processIdListForm",
                  fn: function(ref) {
                    var type = ref.type
                    var disabled = ref.disabled
                    return [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择工序", multiple: "" },
                          model: {
                            value: _vm.form.processIdList,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "processIdList", $$v)
                            },
                            expression: "form.processIdList"
                          }
                        },
                        _vm._l(_vm.processList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ]
                  }
                },
                {
                  key: "shiftIdForm",
                  fn: function(ref) {
                    var type = ref.type
                    var disabled = ref.disabled
                    return [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择班组类型" },
                          model: {
                            value: _vm.form.shiftId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "shiftId", $$v)
                            },
                            expression: "form.shiftId"
                          }
                        },
                        _vm._l(_vm.shiftSelectTypeList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ]
                  }
                },
                {
                  key: "status",
                  fn: function(scope) {
                    return undefined
                  }
                },
                {
                  key: "menu",
                  fn: function(ref) {
                    var row = ref.row
                    return undefined
                  }
                }
              ]),
              model: {
                value: _vm.form,
                callback: function($$v) {
                  _vm.form = $$v
                },
                expression: "form"
              }
            },
            [
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "200px !important",
                        marginRight: "5px"
                      },
                      attrs: { placeholder: "请选择车间", size: "small" },
                      model: {
                        value: _vm.form.workshopId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "workshopId", $$v)
                        },
                        expression: "form.workshopId"
                      }
                    },
                    _vm._l(_vm.workShopList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "200px !important",
                        marginRight: "5px"
                      },
                      attrs: { placeholder: "请选择班组类型", size: "small" },
                      model: {
                        value: _vm.form.shiftId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "shiftId", $$v)
                        },
                        expression: "form.shiftId"
                      }
                    },
                    _vm._l(_vm.shiftSelectTypeList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticStyle: { width: "200px", marginRight: "5px" },
                    attrs: { placeholder: "nameOrCode", size: "small" },
                    model: {
                      value: _vm.nameOrCode,
                      callback: function($$v) {
                        _vm.nameOrCode = $$v
                      },
                      expression: "nameOrCode"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }